.canvas {
    height: 100%;
    width: 100%;
}

.container {
    height: 100%;
    width: 100%;
    position: relative;
    background-color: lightcyan;
}

.button {
    z-index: 999;
    position: absolute;
    top: 0;
    left: 0;
}
