.app {
  display: flex;
  flex-flow: column nowrap;
  text-align: center;
  height: 100vh;
  background-color: antiquewhite;
}

.header {
  height: 5rem;
}

.main {
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.visualiserContainer {
  width: 100%;
  height: 100%;
  background-color: aliceblue;
  display: flex;
  justify-content: center;
}

.standardVisualiserContainer, .circleVisualiserContainer {
  width: 30%;
  background-color: aliceblue;
}


.playerContainer {
  height: 80px;
}

