.fpsMeter {
    font-size: 1.5em;
    line-height: 1.5em;
    color: black;
    font-weight: bold;
}

.fpsMeterContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    height: 3em;
    width: 3em;
    border-radius: 1.5em;
    border: 2px solid black;
}

.fpsMeterTitle {
    font-weight: bold;
    color: #ffffff;
}
