.htmlVisualiser {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-end;
    position: relative;
    overflow: hidden;
}


.line {
    flex: 1 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: rgba(0, 0, 0, 0.5);
}

.button {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;
}

.container {
    background-color: #7171f3;
    height: 100%;
    width: 100%;
    position: relative;
}
